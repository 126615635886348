<template>
  <div class="space-y-4">
    <asom-grid :sm="1" :md="3">
      <asom-form-field label="CDF No.">
        <p class="font-semibold">{{ referenceNo }}</p>
      </asom-form-field>
      <asom-form-field label="Station Name">
        <p class="font-semibold">{{ stationName }}</p>
      </asom-form-field>
      <asom-form-field label="TE Bag S/No">
        <p class="font-semibold">{{ teBagNo }}</p>
      </asom-form-field>
      <asom-form-field label="Source of Cash">
        <p class="font-semibold">{{ sourceOfCash }}</p>
        <div class="max-w-lg">
          <div v-if="sourceOfCashEnum === CDF_ENUM.sourceOfCash.PSM">
            <dl class="sm:grid sm:grid-cols-2 gap-2 text-sm ">
              <dt>Station PSM Name</dt>
              <dd class="font-semibold">{{psmName}}</dd>
            </dl>
          </div>
          <div v-else-if="sourceOfCashEnum === CDF_ENUM.sourceOfCash.PreEncodedTicket">
            <dl class="grid sm:grid-cols-2 gap-2 text-sm ">
              <dt>Event</dt>
              <dd class="font-semibold">{{get(transactionData, 'eventName')}}</dd>
              <dt>Number of tickets sold</dt>
              <dd class="font-semibold">{{get(transactionData, 'noOfTickets')}}</dd>
              <dt>Transaction Ids</dt>
              <dd class="font-semibold">
                {{get(transactionData, 'preEncodedTicketTransNos', []).join(', ')}}</dd>
            </dl>
          </div>
          <div v-else-if="sourceOfCashEnum === CDF_ENUM.sourceOfCash.CashFloat">
            <dl class="sm:grid sm:grid-cols-2 gap-2 text-sm">
              <dt>Certis Bags</dt>
              <dd class="font-semibold">
                {{get(transactionData, 'certisBags', []).join(', ')}}</dd>
              <dt>Transaction Ids</dt>
              <dd class="font-semibold">
                {{get(transactionData, 'certisBagsTransNo', []).join(', ')}}</dd>
            </dl>
          </div>
          <div v-else-if="sourceOfCashEnum === CDF_ENUM.sourceOfCash.CoinFlush">
            <dl class="grid sm:grid-cols-2 gap-2 text-sm">
              <dt>Coin Flush Ids</dt>
              <dd class="font-semibold">
                {{get(transactionData, 'coinFlushReferenceNos', []).join(', ')}}</dd>
            </dl>
          </div>
        </div>
      </asom-form-field>
      <asom-form-field label="Date Created" class="col-span-2">
        <p class="font-semibold">{{displayUtcDate(dateCreated)}}</p>
      </asom-form-field>
      <asom-form-field label="Remarks" class="col-span-3">
        <p class="font-semibold">{{ remarks }}</p>
      </asom-form-field>
    </asom-grid>
    <div class="bold-text">Monetary Values</div>
    <div class="flex flex-col">
      <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table class="min-w-full divide-y divide-gray-200">
              <caption class="hidden"></caption>
              <thead class="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Denominations
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    No. of Notes/Coins
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Value
                  </th>
                </tr>
              </thead>
              <tbody class="bg-white divide-y divide-gray-200">
                <template v-for="(item, i) in commonCoinAndNoteList" :key="i">
                  <tr>
                    <td class="denominations">
                      {{item.title}}
                    </td>
                    <td class="noOfNotes">
                      <p>{{ item.count }}</p>
                    </td>
                    <td class="value">
                      <p>{{ item.formattedAmount }}</p>
                    </td>
                  </tr>
                </template>
                <tr v-if="uncommonNoteList.length > 0">
                  <td colspan="3" class="denominations">Uncommon Notes</td>
                </tr>
                <template v-for="(item, i) in uncommonNoteList" :key="i">
                  <tr>
                    <td class="denominations">
                      {{item.title}}
                    </td>
                    <td class="noOfNotes">
                      <p>{{ item.count }}</p>
                    </td>
                    <td class="value">
                      <p>{{ item.formattedAmount }}</p>
                    </td>
                  </tr>
                </template>
              </tbody>
              <tfoot>
                <!-- <tr>
                  <td>
                    <div class="flex flex-col items-start">
                      <div class="pl-2">Total ${{ totalOfNotes }}</div>
                    </div>
                  </td>
                  <td>
                    <div class="flex flex-col items-start">
                      <div class="pl-2">Total ${{ totalOfCoins }}</div>
                    </div>
                  </td>
                </tr> -->
                <tr>
                  <td colspan="2" class="summary">
                    Total cash in Cash Bag
                  </td>
                  <td class="summary">
                    {{ totalAmount }}
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import get from 'lodash.get'
import { displayUtcDate } from "@/helpers/dateTimeHelpers";
import { cdf } from '@/constants/APIEnums/cashManagementEnums'
import { formatCurrency, multiply } from '../../../helpers/numbers';

function createListItem(title, count, value) {
  return {
    title: title,
    value: value,
    count: count,
    amount: multiply(value, count),
    formattedAmount: formatCurrency(multiply(value, count))
  }
}

export default {
  props: [
    'count1Cents',
    'count2Cents',
    'count5Cents',
    'count10Cents',
    'count20Cents',
    'count50Cents',
    'count1Dollar',
    'count1DollarNote',
    'count2Dollar',
    'count5Dollar',
    'count10Dollar',
    'count20Dollar',
    'count25Dollar',
    'count50Dollar',
    'count100Dollar',
    'count500Dollar',
    'count1000Dollar',
    'count10000Dollar',
    'referenceNo',
    'dateCreated',
    'sourceOfCash',
    'sourceOfCashEnum',
    'stationName',
    'remarks',
    'teBagNo',
    'transactionData',
    'psmName',
  ],
  computed: {
    CDF_ENUM() {
      return cdf
    },
    commonCoinAndNoteList() {
      let list = [];
      list.push(createListItem('1¢', this.count1Cents, .01));
      list.push(createListItem('2¢', this.count2Cents, .02));
      list.push(createListItem('5¢', this.count5Cents, .05));
      list.push(createListItem('10¢', this.count10Cents, .1));
      list.push(createListItem('20¢', this.count20Cents, .2));
      list.push(createListItem('50¢', this.count50Cents, .5));
      list.push(createListItem('$1', this.count1Dollar, 1));
      list.push(createListItem('$2', this.count2Dollar, 2));
      list.push(createListItem('$5', this.count5Dollar, 5));
      list.push(createListItem('$10', this.count10Dollar, 10));
      list.push(createListItem('$50', this.count50Dollar, 50));
      list.push(createListItem('$100', this.count100Dollar, 100));
      list.push(createListItem('$1000', this.count1000Dollar, 1000));
      list.push(createListItem('$10000', this.count10000Dollar, 10000));
      return list.filter(item => item.count > 0);
    },
    uncommonNoteList() {
      let list = [];
      list.push(createListItem('$1 (note)', this.count1DollarNote, 1));
      list.push(createListItem('$20', this.count20Dollar, 20));
      list.push(createListItem('$25', this.count25Dollar, 25));
      list.push(createListItem('$500', this.count500Dollar, 500));
      return list.filter(item => item.count > 0);
    },
    totalAmount() {
      if (this.sourceOfCashEnum === cdf.sourceOfCash.CoinFlush)
        return 'N.A';
      const sum = this.commonCoinAndNoteList.reduce((sum, item) => sum + item.amount, 0) + 
        this.uncommonNoteList.reduce((sum, item) => sum + item.amount, 0);
      return formatCurrency(sum);
    }
  },
  methods: {
    get,
    displayUtcDate
  }
}
</script>
<style scoped>
.denominations, .noOfNotes, .value {
  @apply px-6 py-4 whitespace-nowrap text-sm text-gray-900
}

.value {
  @apply font-bold;
}

.summary {
  @apply px-6 py-4 whitespace-nowrap text-lg font-bold text-gray-900
}

.bold-text {
  @apply text-gray-500 font-bold;
}
</style>