export const formDataMixin = {
  data() {
    return {
      formData: {
        count1Cents: this.$route.params.count1Cents || 0,
        count2Cents: this.$route.params.count2Cents || 0,
        count5Cents: this.$route.params.count5Cents || 0,
        count10Cents: this.$route.params.count10Cents || 0,
        count20Cents: this.$route.params.count20Cents || 0,
        count50Cents: this.$route.params.count50Cents || 0,
        count1Dollar: this.$route.params.count1Dollar || 0,
        count1DollarNote: this.$route.params.count1DollarNote || 0,
        count2Dollar: this.$route.params.count2Dollars || 0,
        count5Dollar: this.$route.params.count5Dollars || 0,
        count10Dollar: this.$route.params.count10Dollars || 0,
        count20Dollar: this.$route.params.count20Dollars || 0,
        count25Dollar: this.$route.params.count25Dollars || 0,
        count50Dollar: this.$route.params.count50Dollars || 0,
        count100Dollar: this.$route.params.count100Dollars || 0,
        count500Dollar: this.$route.params.count500Dollars || 0,
        count1000Dollar: this.$route.params.count1000Dollars || 0,
        count10000Dollar: this.$route.params.count10000Dollars || 0,
        referenceNo: this.$route.params.referenceNo || "",
        dateCreated: this.$route.params.dateCreated || "",
        sourceOfCash: this.$route.params.sourceOfCash || "",
        sourceOfCashEnum: this.$route.params.sourceOfCashEnum || "",
        stationName: this.$route.params.stationName || "",
        psmName: this.$route.params.psmName || "",
        remarks: this.$route.params.remarks || "",
        teBagNo: this.$route.params.teBagNo || "",
        witnessName: this.$route.params.witnessName || "",
        baggerName: this.$route.params.baggerName || "",
        transactionData: null,
        witnessSignatureId: null,
        baggerSignatureId: null,
      },
    };
  },
  methods: {
    
    setFormData({ data, transData }) {
      this.formData.count1Cents = data.count1Cents;
      this.formData.count2Cents = data.count2Cents;
      this.formData.count5Cents = data.count5Cents;
      this.formData.count10Cents = data.count10Cents;
      this.formData.count20Cents = data.count20Cents;
      this.formData.count50Cents = data.count50Cents;
      this.formData.count1Dollar = data.count1Dollar;
      this.formData.count1DollarNote = data.count1DollarNote;
      this.formData.count2Dollar = data.count2Dollars;
      this.formData.count5Dollar = data.count5Dollars;
      this.formData.count10Dollar = data.count10Dollars;
      this.formData.count20Dollar = data.count20Dollars;
      this.formData.count25Dollar = data.count25Dollars;
      this.formData.count50Dollar = data.count50Dollars;
      this.formData.count100Dollar = data.count100Dollars;
      this.formData.count500Dollar = data.count500Dollars;
      this.formData.count1000Dollar = data.count1000Dollars;
      this.formData.count10000Dollar = data.count10000Dollars;
      this.formData.referenceNo = data.referenceNo;
      this.formData.dateCreated = data.dateCreated;
      this.formData.sourceOfCash = data.sourceOfCash;
      this.formData.sourceOfCashEnum = data.sourceOfCashEnum;
      this.formData.remarks = data.remarks;
      this.formData.teBagNo = data.teBagNo;
      this.formData.stationName = data.stationName;
      this.formData.psmName = data.psmName;
      this.formData.witnessName = data.witnessName;
      this.formData.baggerName = data.baggerName;
      this.formData.baggerSignatureId = data.baggerSignatureId;
      this.formData.witnessSignatureId = data.witnessSignatureId;
      this.formData.transactionData = transData;
    },
  },
}

export const modalMixin = {
  data() {
    return {
      showModal: false,
    };
  },
  methods: {
    submitClicked() {
      this.showModal = true;
    },
    onCloseModal() {
      this.showModal = false;
    },
  }
}
